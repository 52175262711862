import { createContext, useState, useEffect } from "react";
import AppContract from "../output/Web3ReactBoilerplate.json";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import Web3Utils from "web3-utils";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";

const injected = new InjectedConnector({
  supportedChainIds: [1, 5],
});

const RPC_URLS = {
  1: "https://mainnet.infura.io/v3/00ca1859789d4b40bce01f4104844224",
};

const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  supportedChainIds: [1],
  network: "ethereum",
  qrcode: true,
  pollingInterval: 12000,
});

const walletlink = new WalletLinkConnector({
  supportedChainIds: [1],
});

// Contract Address on NET
// Contract URL https://rinkeby.etherscan.io/address/0xf204019bb6126d690dd367cdcdeb5c4a63d3b186#writeContract
const CONTRACT_ADDRESS = '0x64CfC8237fA894A3db946C1F4A4feddCCe0B2f81';
const CORRECT_NET_ID = 1;
// const CONTRACT_ADDRESS = '0xf1714e61A832c7E35F2E09970B662B990FC56A28';
// const CORRECT_NET_ID = 5;

export const DAppContext = createContext(null);

export const DAppProvider = ({ children }) => {
  const [instance, setInstance] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [transactionHash, setTransactionHash] = useState("");
  const [loading, setLoading] = useState(false);
  const [networkId, setNetworkId] = useState(1);
  const [contractDetails, setContractDetails] = useState(null);
  const [provider, setprovider] = useState();

  const web3Context = useWeb3React();

  const { connector, account, activate, deactivate, active, error } =
    web3Context;

  const prov = new WalletConnectProvider({
    infuraId: "00ca1859789d4b40bce01f4104844224", // Required
  });

  // const currentConnector = injected;
  const connected =
    connector === injected ||
    connector === walletconnect ||
    connector === walletlink;
  console.log("connected: ", connected);

  const initialWebWalletSync = async () => {
    try {
      let provider;
      if (connector === walletconnect) {
        provider = prov;
        await prov.enable();
      } else {
        provider = await detectEthereumProvider();
      }
      setprovider(provider);
      if (provider) {
        const web3 = new Web3(provider);

        const accounts = await web3.eth.getAccounts();
        const networkID = await web3.eth.net.getId();

        const instance = new web3.eth.Contract(
          AppContract.abi,
          CONTRACT_ADDRESS
        );

        setAccounts(accounts);
        setNetworkId(Number(networkID));
        setInstance(instance);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const connectWallet = async (wallet) => {
    setLoading(true);
    try {
      if (networkId !== CORRECT_NET_ID) {
        throw new Error(`Please change to Rinkeby Network`);
        alert("Please change to Rinkeby Network");
        return;
      }
      switch (wallet) {
        case 1:
          await activate(injected);
          break;
        case 2:
          await activate(walletconnect);
          break;
        case 3:
          await activate(walletlink);
          break;
      }
      setLoading(false);
    } catch (error) {
      console.log(error, "Error");
    }
  };

  const weiToEther = (wei) => {
    return Web3Utils.fromWei(wei);
  };

  const mint = async (count) => {
    try {
      setLoading(true);
      console.log("account:", account);
      if (!instance) throw new Error(`No instance`);
      if (!accounts.length)
        throw new Error(`No account selected. Try reauthenticating`);
      if (!count) throw new Error(`No token count provided.`);
      if (networkId !== CORRECT_NET_ID) alert("Please change to MainNet");

      const { paused, mint } = instance.methods;

      const isPaused = await paused()?.call();

      // if (!isPaused) {
      // await mint(count).estimateGas({
      //     from: account,
      //     value: Web3Utils.toWei(
      //         (Number(contractDetails.price) * count).toString(),
      //         "ether"
      //     ),
      //     to: CONTRACT_ADDRESS,
      // }).then({
      //     await mint(count).send({
      //         from: account,
      //         value: Web3Utils.toWei(
      //             (Number(contractDetails.price) * count).toString(),
      //             "ether"
      //         ),
      //         to: CONTRACT_ADDRESS,
      //         gasPrice: 4700000
      //     })
      //         .once("error", (err) => {
      //             alert(err.stack);
      //         })
      //         .then((success) => {
      //             if (success?.status) {
      //                 setTransactionHash(success.transactionHash)
      //                 alert("Congratulations. Your NFT's successfully claimed");
      //                 setLoading(false);
      //             }
      if (!isPaused) {
        mint(count)
          .estimateGas({
            from: "0xDA9dfA130Df4dE4673b89022EE50ff26f6EA73Cf",
            value: Web3Utils.toWei(
              (Number(contractDetails.price) * count).toString(),
              "ether"
            ),
          })
          .then(function (gasPrice) {
            console.log(gasPrice);
            mint(count)
              .send({
                from: account,
                value: Web3Utils.toWei(
                  (Number(contractDetails.price) * count).toString(),
                  "ether"
                ),
                to: CONTRACT_ADDRESS,
                gasLimit: (gasPrice + 50000).toString(),
              })
              .once("error", (err) => {
                alert(err.stack);
              })
              .then((success) => {
                if (success?.status) {
                  setTransactionHash(success.transactionHash);
                  alert("Congratulations. Your NFT's successfully claimed");
                  setLoading(false);
                }
              });
          })
          .catch((e) => {
            mint(count)
              .send({
                from: account,
                value: Web3Utils.toWei(
                  (Number(contractDetails.price) * count).toString(),
                  "ether"
                ),
                to: CONTRACT_ADDRESS,
                gas: "28000000",
              })
              .once("error", (err) => {
                alert(err.stack);
              })
              .then((success) => {
                if (success?.status) {
                  setTransactionHash(success.transactionHash);
                  alert("Congratulations. Your NFT's successfully claimed");
                  setLoading(false);
                }
              });
            console.log("kjbdcbdooooooooo");
          });
      } else {
        throw new Error(`Sales is paused!`);
      }
    } catch (error) {
      console.log("kjbbjcsbcsbiosbcoix");
      console.log(error);
      setLoading(false);
    }
  };

  const getContractDetails = async () => {
    if (!instance) return null;
    let details = {};
    console.log(instance.methods);

    const { cost, name, maxMintAmount, totalSupply, maxSupply } =
      instance.methods;

    const collectionName = await name()?.call();
    const maxAllowedTokensPerPurchase = await maxMintAmount()?.call();
    const maxMintSupply = await maxSupply()?.call();
    const totalSupplyNFT = await totalSupply()?.call();
    let price = weiToEther(`${await cost()?.call()}`);

    details = {
      collectionName,
      price,
      maxAllowedTokensPerPurchase,
      maxMintSupply,
      totalSupplyNFT,
    };

    setContractDetails(details);
  };

  const resetTransactionHash = () => {
    setTransactionHash("");
  };

  useEffect(() => {
    initialWebWalletSync();
  }, [active, connector]);

  useEffect(() => {
    getContractDetails();
  }, [instance]);

  useEffect(() => {
    return () => {
      deactivate();
    };
  }, []);

  return (
    <DAppContext.Provider
      value={{
        connectWallet,
        mint,
        active,
        deactivate,
        loading,
        transactionHash,
        connector,
        resetTransactionHash,
        connected,
        contractDetails,
        provider,
      }}
    >
      {children}
    </DAppContext.Provider>
  );
};
