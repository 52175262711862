import { useContext, useState } from "react";
import { DAppContext } from "../../context";

const Mint = () => {
  const [count, setCount] = useState(1);
  const {
    contractDetails,
    transactionHash,
    resetTransactionHash,
    mint,
    loading,
  } = useContext(DAppContext);

  if (!contractDetails) return null;

  const {
    collectionName,
    price,
    maxAllowedTokensPerPurchase,
    maxMintSupply,
    totalSupplyNFT,
  } = contractDetails;

  const subCount = () => {
    if (count === 1) return;

    if (count > 0) {
      setCount(() => count - 1);
    }
  };
  const addCount = () => {
    if (count < maxAllowedTokensPerPurchase) {
      setCount(() => count + 1);
    }
  };

  return (
    <div className="flex flex-col text-white items-center">
      <div className="flex my-2">
        {transactionHash && (
          <>
            <a
              href={`https://etherscan.io/tx/${transactionHash}`}
              target="_blank"
              rel="noreferrer"
            >
              View on Block Explorer
            </a>
            <hr sx={{ my: 2 }} />
          </>
        )}
      </div>
      <span className="py-4">
        <p className="tracking-wider text-xl md:text-3xl inline block">MINTING PRICE:</p>{" "}
        <p
          className="ml-4 inline block text-xl md:text-3xl font-bold"
          style={{ color: "#9dcb2f" }}
        >
          {price}ETH
        </p>
      </span>
      {/* <h4>
                {maxAllowedTokensPerPurchase} MAX
                </h4> */}

      <div
        className="flex flex-col items-center w-full border rounded"
        style={{ borderColor: "#C3FB35" }}
      >
        <span className="font-light text-lg py-2">
          ADD <p className="font-semibold inline">NFTiST</p> TO THE CART
        </span>
        <div
          className="w-11/12 h-0.5 px-2"
          style={{ backgroundColor: "#C3FB35" }}
        ></div>
        <div className="flex justify-around w-11/12 my-4">
          <button
            className="bg-white p-2 rounded"
            onClick={subCount}
            disabled={loading}
          >
            <svg
              width="16"
              height="2"
              viewBox="0 0 16 2"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H15C15.2652 2 15.5196 1.89464 15.7071 1.70711C15.8946 1.51957 16 1.26522 16 1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0Z"
                fill="black"
              />
            </svg>
          </button>
          <h4 className="font-bold" style={{ color: "#9dcb2f" }}>
            {count}
          </h4>
          <button
            className="bg-white p-2 rounded"
            onClick={addCount}
            disabled={loading}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 7H9V1C9 0.734784 8.89464 0.48043 8.70711 0.292893C8.51957 0.105357 8.26522 0 8 0C7.73478 0 7.48043 0.105357 7.29289 0.292893C7.10536 0.48043 7 0.734784 7 1V7H1C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H7V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V9H15C15.2652 9 15.5196 8.89464 15.7071 8.70711C15.8946 8.51957 16 8.26522 16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652 7 15 7Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <span className="py-4">
          <p className="tracking-wider text-xl inline block">TOTAL PRICE:</p>{" "}
          <p
            className="ml-4 inline block text-xl font-bold"
            style={{ color: "#9dcb2f" }}
          >
            {(count * price).toFixed(2)}ETH
          </p>
        </span>
      </div>

      <button
        type="button"
        onClick={() => mint(count)}
        disabled={loading}
        className="w-32 px-4 py-2 my-10 text-black font-bold grad rounded transform transition duration-300 hover:scale-110 flex-justify-center"
      >
        {loading ? (
          <div className="bg-purple-500 h-6 w-6 rounded-full animate-ping m-2" style={{backgroundColor: '#9dcb2f'}}></div>
        ) : (
          "Mint"
        )}
      </button>

      {/* <div className="text-center">
        <h4>
          {totalSupplyNFT} / {maxMintSupply} minted
        </h4>
      </div> */}
    </div>
  );
};

export default Mint;
