import React from 'react';
// import a from '../assets/logo.png';
import { useNavigate } from "react-router-dom";

function Header(props) {
    const navigate = useNavigate();
    return (
        <div className="h-20 flex justify-between items-center">

            <a href="/" className="flex-1">

                {/* <img className="h-12 ml-10 md:ml-20 hover:drop-shadow-[0px_2px_15px_rgba(128,0,128,1)]" src={a} /> */}
            </a>

            <div className="p-2 w-0 md:w-auto">
                <button className="w-0 md:w-auto invisible md:visible mr-8 text-white hover:text-[#800080] hover:drop-shadow-[0px_2px_10px_rgba(255,255,255,1)]" onClick={() => { navigate("/") }}>Home</button>
                <button className="w-0 md:w-auto invisible md:visible mr-8 text-white hover:text-[#800080] hover:drop-shadow-[0px_2px_10px_rgba(255,255,255,1)]" onClick={() => { props.handleScroll('about') }}>About</button>
                <button className="w-0 md:w-auto invisible md:visible mr-8 text-white hover:text-[#800080] hover:drop-shadow-[0px_2px_10px_rgba(255,255,255,1)]" onClick={() => { props.handleScroll('roadmap') }}>Roadmap</button>
                <button className="w-0 md:w-auto invisible md:visible mr-8 text-white hover:text-[#800080] hover:drop-shadow-[0px_2px_10px_rgba(255,255,255,1)]" onClick={() => { props.handleScroll('team') }}>Team</button>
                <button className="w-0 md:w-auto invisible md:visible mr-8 text-white hover:text-[#800080] hover:drop-shadow-[0px_2px_10px_rgba(255,255,255,1)]" onClick={() => { props.handleScroll('faqRef') }}>Faq</button>

                <button className="w-0 md:w-auto invisible md:visible mr-8 ml-16 text-white hover:bg-purple-900 px-8 py-2 rounded-xl border border-white lg:mr-40" onClick={() => navigate("/mint")}>Mint</button>
            </div>
                <button className="p-2 mr-4 w-auto md:w-0 md:invisible" onClick={() => { props.setshow(!props.show) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#ffffff">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
        </div>
    );
}

export default Header;
