import React, { useState, useEffect, useContext } from "react";
import { DAppContext } from "../context";
import Mint from "../components/Mint";
import ConnectWallet from "../components/ConnectWallet";
import { useNavigate } from "react-router-dom";
import NavLogo from "../components/NavLogo";
import frame from "../assets/frame 85pcent.png";
import HeroGIF from "../assets/hero-gif.gif";
import bottom from "../assets/bottom.png";
import bottom1 from "../assets/bottom1.png";

function MintPage() {
  const { active, connected, deactivate } = useContext(DAppContext);
  const navigate = useNavigate();


  return (
    <div className="bg-cover bg-fixed bg-black">
      
      <div className="container min-h-screen mx-auto relative">
      <div className="h-20 flex justify-between items-center absolute top-0">
        <a href="/" className="px-4 py-20 flex">
          <NavLogo className="h-16 md:h-auto"/>
          <button
            className="mx-4 text-white text-xl md:text-2xl"
            onClick={() => {
              window.location.href = "https://nftist-app.web.app/";
            }}
          >
            {active && connected ? "WALLET CONNECTED": "MINTING PAGE"}
          </button>
        </a>
      </div>
        <div className="flex flex-col md:flex-row items-center w-full h-full">
          {/* <h1 className="text-gray-300 text-xl mt-16">Price to be announced in our Discord…</h1>
                   { !(active && connected) ? <button className="px-8 py-2 font-bold text-white border rounded-lg mx-6 border-white hover:text-purple-900 hover:bg-purple-300 my-10" onClick={()=>{console.log('khbhvjh')}}>Mint</button>:<div></div>} */}
          <div className="w-full md:w-1/2 h-full flex flex-col items-center justify-center order-2 md:order-1 mb-48 md:mb-0">
            {active && connected ? (
              <div>
                <div className="">
                  <Mint />
                </div>

                <button
                  className=" mx-auto grad rounded-xl flex justify-center font-semibold p-4"
                  onClick={() => {
                    deactivate();
                  }}
                >
                  Disconnect
                </button>
              </div>
            ) : (
              <div className="">
                <ConnectWallet />
              </div>
            )}
          </div>
          <div className="w-full h-96 md:h-auto mt-16 md:mb-20 md:w-1/2 relative p-10  md:p-20 flex flex-col items-center justify-center order-1 md:order-2">
            <img className="w-full z-10" src={frame} />
            <div className="absolute z-0 p-20 md:p-36">
              <img className="w-full " src={HeroGIF} alt="nftist gif" />
            </div>
          </div>
          {/* <div className="container mx-auto"> */}
            <div className="absolute bottom-0 w-full flex md:flex-row flex-col justify-center md:justify-between items-center md:items-end px-2 md:px-8 py-4">
                    <img src={bottom} className="invisible md:visible w-0 md:w-auto" />
                    <img src={bottom1} className="md:invisible visible md:w-0 w-auto mb-4"/>
                    <h1 className="text-white font-light text-sm md:text-base mb-2">© 2022, All rights reserved.</h1>
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default MintPage;
