import Header from './components/Header';
import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { DAppProvider } from "./context";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import MintPage from './views/MintPage';

const libraryProvider = (provider) => {
  console.log('Provider', provider);
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000;
  return library;
}


function App() {
  return (
    < >
      <Web3ReactProvider getLibrary={libraryProvider}>
        <DAppProvider>
          {/* <Header /> */}
          <Router>
            <Routes>
              <Route path='/' element={<MintPage/>} />
            </Routes>
          </Router>
        </DAppProvider>
      </Web3ReactProvider>
    </>
  );
}

export default App;
