import { useContext } from "react";
import { DAppContext } from "../../context";
import meta from '../../assets/meta.webp';
import trust from '../../assets/trust.webp';
import coinbase from '../../assets/coinbase.png';
import {isMobile} from 'react-device-detect';

const ConnectWallet = () => {
    const {
        loading,
        connectWallet, 
        provider
    } = useContext(DAppContext);
    return (
        <div className='flex flex-col items-center'>
            {loading ? <div className="h-6 w-6 rounded-full animate-ping" style={{backgroundColor: '#9dcb2f'}}></div> :
                <>
                <h1 className="text-white my-2 md:my-4 text-lg md:text-3xl tracking-wider" style={{color: '#9dcb2f'}}>FIND YOUR PLACE IN THE NFT SPACE</h1>
                <h1 className="text-white font-bold my-2 md:my-4 text-xl md:text-4xl tracking-wider">CONNECT YOUR WALLET</h1>
                <div className="flex flex-col md:flex-row">
                    <button className="bg-white rounded-xl px-2 mx-2 mb-4" onClick={()=>{if(provider){connectWallet(1) }else if(isMobile){window.location.href='https://metamask.app.link/dapp/mint.thenftist.com'}}}><div className="flex h-12 items-center px-2 py-2 my-1 mx-2"><img className="object-contain h-10 mx-1" src={meta}/>Metamask</div></button>
                    {/* <button className="bg-white rounded-xl px-2 mx-2 mb-4" onClick={()=>{connectWallet(2)}}><div className="flex h-12 items-center px-2 py-2 my-1 mx-2 "><img className="object-contain h-10 mx-1" src={trust}/>Trust Wallet</div></button>
                    <button className="bg-white rounded-xl px-2 mx-2 mb-4" onClick={()=>{connectWallet(3)}}><div className="flex h-12 items-center px-2 py-2 my-1 mx-2 "><img className="object-contain h-10 mx-1" src={coinbase}/>Coinbase</div></button> */}
                                </div>
                </>
            }
        </div>
    )
}

export default ConnectWallet